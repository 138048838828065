import React from 'react'

import { Link } from "gatsby"

import { date } from "../../hooks/upcc-date"

import Layout from "../share/upcc-layout";
import Footer from "../share/footer"
import { BlogContainer, NavContainer, MainContainer } from "./styles";
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, TumblrShareButton, PinterestShareButton } from "react-share";
import { useLocation } from "@reach/router";
import CategoriesResponsive from "../arteycultura/CategoriesResponsive";

import Header from "../Header/upcc"

const Blog = ({ pageContext: { dataSingle } }) => {
  const {
    title,
    image,
    content: { json },
    address,
    price,
    link
  } = dataSingle;

  const _date = dataSingle.date

  console.log({ title, image, json, _date, address, price, link })

  const { pathname } = useLocation()
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
      },
      [INLINES.HYPERLINK]: (node) => {
        if ((node.data.uri).includes("<iframe")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div className="rich-content-iframe" dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    },
    renderMark: {},
  }

  return (
    <Layout>

      <Header/>

      <div class="upcc-article-wrapper">

        <section class="upcc-menu">
          <nav>
            <Link to={`/home`}>Inicio</Link>
            &nbsp;/ <Link to={`/agenda`}>Agenda</Link>
          </nav>
        </section>

        <article class="upcc-event-wrapper">
          <div class="upcc-event">
            <img src={ image.file.url }/>

            <div class="upcc-event-content">

              <h5 class="upcc-h5">Datos del Evento</h5> <br/>

              <div class="upcc-event-items">
                <p class="upcc-event-item">
                  <i class="fa fa-calendar"></i>&nbsp;&nbsp;&nbsp;
                  { date(_date).str }
                </p>
                <p class="upcc-event-item">
                  <i class="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp;
                  { address }
                </p>
                <p class="upcc-event-item">
                  <i class="fa fa-clock-o"></i>&nbsp;&nbsp;&nbsp;
                  { date(_date).hour }
                </p>

                {price
                  ? <p class="upcc-event-item">
                      <i class="fa fa-ticket"></i>&nbsp;&nbsp;&nbsp;
                      Desde S/ { price }
                    </p>
                  : <i/>
                }

              </div>

              <div class="separator"></div> <br/>

              <span class="upcc-tag purple">EVENTOS</span> <br/>

              <div class="upcc-content">

                { documentToReactComponents(json, options) }

              </div> <br/><br/>


              {link
                ? <a class="upcc-button" href={link} target="_blank" rel="noopener noreferrer">Link del evento</a>
                : <i/>
              }

            </div>
          </div>
        </article>

      </div>
      <Footer />
    </Layout>
  )
}

export default Blog
